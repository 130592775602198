@font-face {
    font-family: 'Bebas Neue Regular';
    src: url(../assets/fonts/BebasNeue-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Cabin Regular';
    src: url(../assets/fonts/Cabin-VariableFont_wdth.ttf) format('truetype');
}

:root {
    font-family: 'Cabin Regular';
    ion-modal.fullscreen {
       
        --border-radius: 0;
    }
    .mini {
        --width: 300px;
        --height: auto;
        --border-radius: 0;
    }
}

.iframeStyle {
    width: 100%;
    height: 100%!important
}

.button {
    font-family: 'Cabin Regular';
    text-transform: capitalize;
}

.bg_app_content_photo {
    width: 100vw;
    height: 100vh;
   
}

.modal_titulo {
    font-size: 40px;
    margin: 2%;
}

.modal_close {
    width: 64px;
    height: 64px;
    position: absolute;
    right: 0px;
    color: red;
    cursor: pointer;
}

.btn_sty {
    width: auto;
    height: 60px;
    margin: 0 auto;
    min-width: 280px;
    font-size: 1.2em;
    font-family: 'Bebas Neue Regular';
}
@media only screen and (max-width: 767px) {
    .menu_movil_home {
        display: block;
    }
    .hide_pc{
        display: none;
    }
}