.box {
    display: flex;
    align-items: center;
    justify-content: center;
    div.card {
        width: 36%;
        height: auto;
        min-height: 150px;
    }
}

.unir {
    display: inline-block;
    font-size: 28px;
    color: var(--ion-color-primary);
    text-align: center;
}

.puntosTiempo {
    text-align: center;
    border-radius: 1rem;
    font-weight: bold;
    color: red;
}

.quiz_puntuacion {
    text-align: center;
    border-radius: 1rem;
    font-weight: bold;
}

.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 2px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.75rem;
    align-items: center;
    justify-content: center;
    .card_autor {
        font-size: xx-small;
        background-color: black;
        color: #fff;
        width: 100%;
        border: 1px;
        padding: 2px 1px;
    }
}

.card_img_top {
    width: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.card_body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 5px;
    width: 100% !important;
    text-align: center;
    font-family: "Open Sans Condensed", sans-serif;
    font-weight: 700;
    color: black;
}

.centro_pagina_quiz {
    width: 100vw;
    height: 100vh;
    background-image: url("/assets/images/fondo_pc.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    --background: transparent;
    background-blend-mode: luminosity;
    background-color: azure;
    background-position: center;
}

.bien {
    background-color: greenyellow !important;
}

.mal {
    background-color: red !important;
}

.w100 {
    width: 100%;
}

.wiki_buttom {
    display: inline-flex;
    justify-content: flex-start;
    top: 80px;
}

.caja_respuesta {
    height: 200px;
    width: 100%;
    display: contents;
    .respuesta {
        width: 48%;
        margin: 20px 1%;
    }
}

.text_quiz_center {
    text-align: center;
}

.iconQuizCabecera {
    height: 40px;
    width: 40px;
}

.textoPregunta {
    font-family: "Open Sans Condensed", sans-serif;
    font-size: 54px;
    font-weight: 700;
    line-height: 54px;
    margin: 0 0 0;
    padding: 20px 30px;
    text-align: center;
    text-transform: uppercase;
}

@media only screen and (max-width: 320px) and (max-height: 440px) {
    //ajusta os valores de iphone 4 aqui
}

@media only screen and (max-width: 360px) and (max-height: 667px) {
    .caja_respuesta {
        height: auto;
        width: 100%;
        display: contents;
        .respuesta {
            width: 98%;
            margin: 5px 1%;
            font-size: 12px;
        }
    }
    .respuestaCorrecta {
        color: #fff;
        font-size: 20px;
        text-align: center;
        background-color: green;
        padding: 10px;
        margin: 10px;
        position: absolute;
        bottom: 60;
    }
    .respuestaIncorrecta {
        padding: 10px;
        margin: 10px;
        //bottom: 60;
    }
    .quiz_puntuacion {
        text-align: center;
        border-radius: 12px;
        font-weight: bold;
        font-size: 20px;
    }
    .iconQuizCabecera {
        height: 3x0px;
        width: 30px;
    }
    .box {
        display: flex;
        align-items: center;
        justify-content: center;
        div.card {
            width: 75%;
            height: auto;
        }
    }
    .text_quiz_center {
        text-align: end;
    }
    .caja_ayuda {
        overflow-y: auto;
        white-space: nowrap;
        height: auto;
    }
    .textoPregunta {
        font-family: "Open Sans Condensed", sans-serif;
        font-size: 22px;
        font-weight: 700;
        line-height: 26px;
        margin: 0 0 0;
        padding: 10px 15px;
        text-align: center;
        text-transform: uppercase;
    }
    .botonContinuar {
        margin: 30px;
        font-size: 30px;
    }
}

@media only screen and (max-width: 375px) and (min-width: 361px) {
    .caja_respuesta {
        height: auto;
        width: 100%;
        display: contents;
        .respuesta {
            width: 98%;
            margin: 5px 1%;
            font-size: 14px;
        }
    }
    .box {
        display: flex;
        align-items: center;
        justify-content: center;
        div.card {
            width: 75% !important;
            height: auto;
        }
    }
}

@media only screen and (max-width: 767px) and (min-width: 360px) {
    .caja_respuesta {
        height: auto;
        width: 100%;
        display: contents;
        .respuesta {
            width: 98%;
            margin: 5px 1%;
        }
    }
    .box {
        display: flex;
        align-items: center;
        justify-content: center;
        div.card {
            width: 100%;
            height: auto;
        }
    }
    .text_quiz_center {
        text-align: end;
    }
    .caja_ayuda {
        overflow-y: auto;
        white-space: nowrap;
        height: auto;
    }
    .textoPregunta {
        font-family: "Open Sans Condensed", sans-serif;
        font-size: 26px;
        font-weight: 700;
        line-height: 26px;
        margin: 0 0 0;
        padding: 20px 30px;
        text-align: center;
        text-transform: uppercase;
    }
    .botonContinuar {
        margin: 30px;
        font-size: 30px;
    }
}

.contenedorAyuda {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: relative;
}

.cantidadAyuda {
    position: absolute;
    bottom: 0;
    right: 0;
}

@media only screen and (max-device-width: 415px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .botonContinuar {
        margin-top: 10px;
        font-size: 26px;
    }
    .newlevel {
        background-color: rgb(2, 132, 2);
        margin: 0px !important;
        padding: 5px !important;
    }
}

.levelChange {
    font-size: 36px;
    color: white;
}

.hide {
    display: none;
}

.show {
    display: block;
}

.showInline {
    display: inline-block;
}

.container_center_item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalMini {
    width: 300px;
    height: auto;
}

.iframeStyle {
    width: 100%;
    height: 100% !important;
}

.newlevel {
    background-color: green;
    margin: 20px;
    padding: 20px;
    color: yellow;
    text-align: center;
    border-radius: 10px;
    font-weight: bold;
}

.btn_continuar {
    font-size: 26px;
}

.btn_guardar {
    font-size: 10px;
}

.caja_boton {
    text-align: center;
    margin-top: 50px;
}



.puntuacion_quiz {
    background-color: white;
    border-radius: 1rem;
}

.caja_ayuda {
    text-align: center;
    width: 100%;
    height: auto;
    vertical-align: middle;
    display: inline-block;
    //overflow-x: scroll;
    overflow-y: hidden;
    img {
        width: 70px;
        width: 70px;
        margin: 10px;
        cursor: pointer;
    }
    IonFabButton {
        width: 70px;
        width: 70px;
        margin: 10px;
        cursor: pointer;
        display: inline-block;
    }
}

.image {
    // position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    //margin: -20px 0px 0 -20px;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotating
/* Safari and Chrome */

{
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

.box_question {
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
}