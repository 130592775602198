.avatar1 {
    width: 200px;
    height: 200px;
}

.headerPhotoProfile {
    text-align: center;
    display: block;
    width: 100%;
}

.headerDataProfile {
    text-align: center;
    display: block;
    width: 100%;
    font-size: 36px;
    color: var(--ion-color-primary);
    font-family: 'Bebas Neue Regular';
}

.cabecera {
    text-align: center;
    display: block;
    width: 100%;
    font-size: 1.2em;
    font-weight: bolder;
    color: var(--ion-color-secondary);
    font-family: 'Bebas Neue Regular';
}

.item_valor {
    text-align: center;
    display: block;
    width: 100%;
    font-size: 2em;
    font-weight: bolder;
    font-family: 'Bebas Neue Regular';
    color: black;
}

.error {
    color: red
}

.verde {
    color: var(--ion-color-success);
}

.color_primary {
    color: var(--ion-color-primay);
}

.maximo_ancho {
    width: 600px;
    text-align: center;
    align-items: center;
}

.logo_img {
    max-height: 80px;
    margin: 10px 5px;
    padding: 4px;
}

.caja_boton {
    width: 100%;
    display: block;
    margin: 80px 20px 0 0;
    text-align: center;
}

.caja_profile {
    width: 90%;
    display: block;
    margin: 5% 5%;
    padding: 10px;
    display: block;
    text-align: center;
    border: 2px solid #e0e0e0;
    border-radius: 20px;
    span {
        font-size: 2rem;
        display: block;
        color: var(--ion-color-success);
        font-weight: bolder;
    }
    h3 {
        font-size: 1.4em;
        color: var(--ion-color-secondary);
    }
}

.caja_logout {
    display: block;
    margin: 60px;
    text-align: center;
}

.version {
    width: 100%;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #696969;
}