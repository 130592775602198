.card_city {
    width: 250px;
    height: auto;
    display: inline-block;
    position: relative;
    text-align: center;
    h3 {
        font-size: 0.9em;
        text-align: center;
        width: 240px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    div {
        width: 240px;
    }
    span {
        width: 100%;
        font-size: 0.7em;
        text-align: right;
        display: block;
        padding-right: 30px;
    }
}

.separador {
    width: 100%;
    border: 1px solid gainsboro;
    background-color: antiquewhite;
}

.titulo {
    text-align: center;
    font-variant-caps: small-caps;
    color: var(--ion-color-primary);
}

.avatar {
    width: 70px;
    height: 70px;
}

.avatar_contenedor {
    width: 80px;
    display: block;
    float: left;
}

.titulo_modal {
    text-align: center;
    font-variant-caps: small-caps;
    color: var(--ion-color-primary);
    margin-top: 10px;
    margin-bottom: 5px;
    display: block;
    z-index: 1;
}

.pointImg{
    cursor: pointer;
}

.imagen_modal {
    width: 100%;
}

.contenedor_comentario {
    min-height: 100px;
    display: block;
}

.ContenedorWikipedia {
    width: 100%;
    height: auto;
    display: block;
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}

.iframeStyle {
    width: 100%;
    height: 100%!important
}