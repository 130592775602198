.moneda{
    font-size: 2em;
    color: white;
    margin: 5px;
    vertical-align: super;
}
.bagge{
    border-radius: 22px;
}
.boton_comprar{
    width: 250px;
}
.card_ayuda{
    //background-color:var(--ion-color-primary);
    padding: 8px;
    .card_ayuda_titulo{
        margin: 5px;
        font-size: 3em;
        color:white;
        text-align: center;
        font-family: "Bebas Neue Regular";
    }
    .card_ayuda_texto{
        font-size: 2em;
        //color:grayscale($color: #000000);
        text-align: center;
        font-family: "Bebas Neue Regular";
    }
}


.caja_profile {
    width: 90%;
    display: block;
    margin: 5% 5%;
    padding: 10px;
    display: block;
    text-align: center;
    border: 2px solid #e0e0e0;
    border-radius: 20px;
    span {
        font-size: 2rem;
        display: block;
        color: var(--ion-color-success);
        font-weight: bolder;
    }
    h3 {
        font-size: 1.4em;
        color: var(--ion-color-secondary);
    }
}