@import "./theme/variables.scss";
@import "./theme/general.scss";
@import "./pages/Login/login.module.scss";
@import "./pages/Dashboard/dashboard.module.scss";
@import "./components/Menu/menulateral.module.scss";
@import "./pages/Ranking/ranking.module.scss";
@import "./pages/City/city.module.scss";
@import "./pages/Photo/photo.module.scss";
@import "./pages/CityDetail/cityDetail.module.scss";
@import "./pages/Store/store.module.scss";
.box_error {
    margin: 20px 10%;
    text-align: center;
    width: 80%;
    font-size: medium;
    color: red;
    padding: 10px;
    border: 2px solid red;
}

.box_question {
    //box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    padding: 1em;
}

.btn_modal {
    width: auto;
    height: 40px;
    margin: 0 auto;
    min-width: 120px;
}

.social_barra {
    display: none;
}

.centro_pagina {
    width: 100vw;
    height: 95vh;
    //background-image: url("/assets/images/fondo_pc.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    --background: transparent;
}

.centro_pagina_photo {
    width: 100vw;
    height: 90vh;
    //background: white;
    overflow: auto !important;
}

.banner_altura {
    height: 100vh;
}

.centro_pagina_opacidad {
    width: 100vw;
    height: 100vh;
    background-image: url("/assets/images/fondo_pc.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    --background: transparent;
    opacity: 0.9;
}

.luminosity {
    background-blend-mode: luminosity;
}

.b {
    height: 50px;
}

.text_end {
    text-align: end;
    color: green;
    font-weight: bold;
}

.text_center {
    text-align: center;
}

.lateral {
    background-color: rgb(255, 255, 255);
    min-height: 95vh;
    height: auto;
    background-position: center top;
    background-attachment: fixed;
}

.scrollbar-track {
    background: #9b6a2f;
}

.hide {
    display: none; //hidden
}

.show {
    display: unset;
}

.opacidad_fondo {
    background-color: white;
    border-radius: 20px;
    width: 95%;
    align-items: center;
    align-content: flex-end;
    overflow-y: hidden;
    margin: 3% 2% 7% 2%;
}

.opacidad_fondo_sin_estacios {
    //background-color: white;
    border-radius: 20px;
    width: 90%;
    align-items: center;
    align-content: flex-end;
    overflow-y: hidden;
    margin: 0 5%;
}

.item_lista {
    font-size: 26px;
    color: coral;
    padding-top: 10px;
    border-bottom: 2px solid darkgoldenrod;
}

.boton_col {
    text-align: end;
}

.iframeStyle {
    width: 100%;
    height: 100% !important;
}

.card-title {
    position: absolute;
    top: 36%;
    font-size: 2em;
    width: 100%;
    font-weight: bold;
    color: #fff;
}

.card-subtitle {
    font-size: 1em;
    position: absolute;
    top: 52%;
    width: 100%;
    color: #fff;
}

.ionCard {
    position: relative;
    text-align: center;
}

.boton_volver {
    color: var(--ion-color-primary-contrast);
    cursor: pointer;
    position: absolute;
}

.boton_volver_detalle {
    color: var(--ion-color-primary-contrast);
    cursor: pointer;
    top: 10px;
}

.img_responsive {
    width: auto;
    max-height: 180px;
    height: 100%;
}

.cardContent {
    width: 200px;
    height: 130px;
    display: inline-block;
}

.horizontal-scroll-wrapper {
    overflow: auto;
    white-space: nowrap;
}

select__menu-list::-webkit-scrollbar {
    width: 1px;
    height: 0px;
}

select__menu-list::-webkit-scrollbar-track {
    background: #f1f1f1;
}

select__menu-list::-webkit-scrollbar-thumb {
    background: #888;
}

select__menu-list::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.barraVolver {
    display: flexbox;
    background-color: red;
}

.tituloBotonVolver {
    font-size: 20px;
    padding-left: 30px;
}

#WidgetTutiempo {
    all: initial;
    * {
        all: unset;
    }
}

#WidgetTutiempo {
    font-family: sans-serif, Arial, Helvetica;
    font-size: 14px;
}

#WidgetTutiempo p {
    margin: 0;
    padding: 5px;
    padding-left: 0;
    padding-right: 0;
}

#WidgetTutiempo .header {
    clear: both;
    float: none;
}

#WidgetTutiempo .header h2 {
    margin: 0;
    margin-top: 10px;
}

#WidgetTutiempo .header p {
    margin: 0;
}

#WidgetTutiempo .date {
    margin: 0;
    padding-top: 10px;
    padding-bottom: 5px;
    clear: both;
    float: none;
}

#WidgetTutiempo .daydata {
    clear: both;
    float: none;
}

#WidgetTutiempo .it {
    line-height: 25px;
    float: left;
    margin-right: 20px;
    height: 50px;
    white-space: nowrap;
}

#WidgetTutiempo .it img {
    float: left;
    margin-right: 10px;
}

#WidgetTutiempo .wind {
    float: left;
    margin-right: 20px;
    line-height: 50px;
    height: 50px;
    white-space: nowrap;
}

#WidgetTutiempo .wind img {
    float: left;
    margin-right: 10px;
}

#WidgetTutiempo .oc {
    float: left;
    margin-right: 20px;
    height: 50px;
}

#WidgetTutiempo .moon {
    line-height: 25px;
    float: left;
    height: 50px;
    white-space: nowrap;
}

#WidgetTutiempo .moon img {
    float: left;
    margin-right: 10px;
}

#WidgetTutiempo .time {
    margin: 0;
    padding: 0;
    clear: both;
    float: none;
    padding-top: 10px;
    padding-bottom: 5px;
}

#WidgetTutiempo .linkTT {
    clear: both;
    float: none;
}

.container_center_item {
    display: flex;
    align-items: center;
}

.padre {
    position: relative;
}

.hijo {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 30%;
    width: 50%;
    margin: -15% 0 0 -25%;
}

.img_bg {
    position: fixed;
    top: 0;
    /* Preserve aspet ratio */
    max-height: 100%;
    background-color: #fff;
    float: right;
}

.img_fin_juego {
    max-height: 80vh;
}

.adsbygoogle {
    border: 1px solid red;
    display: none;
}

.divNombreApp {
    padding: 20px 0px;
    text-align: center;
    width: 100%;
    display: block;
    color: white;
    opacity: 0.8;
    background: rgba(0, 0, 0, 0.4);
    h1 {
        font-size: 3em;
        font-family: "Cabin Regular";
    }
    h2 {
        font-size: 1.5em;
        font-family: "Cabin Regular";
        margin: 0.5em;
    }
}

.sin_fondo {
    background: none;
}

.titulo_modal {
    color: var(--ion-color-primary);
    font-family: "Bebas Neue Regular";
    font-size: 2em;
    text-align: center;
}

.img_avatar_mini {
    width: 50px;
    margin: 5px;
}

.icon_ayuda_perfil {
    width: 50px;
    height: 50px;
    margin: 5px;
}

.menu_global_pc {
    background-color: transparent;
}

.cargando {
    width: 100%;
    height: 100%;
    background-color: rgba(242, 255, 0, 0.392);
    position: absolute;
}

.cargando_titulo {
    text-align: center;
    color: var(--ion-color-primary);
}

@media only screen and (max-width: 767px) {
    .menu_global_pc {
        display: none;
    }
}

.action-sheet-button.sc-ion-action-sheet-ios:last-child {
    background-image: none;
    margin-bottom: 100px;
}

.action-sheet-button.sc-ion-action-sheet-android:last-child {
    background-image: none;
    margin-bottom: 100px;
}

.action-sheet-group.sc-ion-action-sheet-md {
    padding-bottom: 100px !important;
    background-image: none;
}

//controlar la altura en ios con safe area
.banner {
    width: 100%;
    height: 65px;
    display: block;
    background-color: white;
}

.titulo_principal {
    font-size: 2em;
    font-family: "Cabin Regular";
    color: var(--ion-color-primary);
    text-align: center;
}

.texto_centrado {
    text-align: center;
}

@media only screen and (min-width: 767px) {
    .texto_centrado {
        text-align: center;
        margin: 5em 1em;
        font-size: 1.5em;
    }
    .hide_pc_global {
        display: none;
    }
    .centro_pagina {
        width: 100vw;
        height: 100vh;
        background-image: url("/assets/images/fondo_pc.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        --background: transparent;
    }
}

.caja_centrado {
    text-align: center;
    width: 100%;
}

.caja_centrado_button {
    text-align: center;
    width: 100%;
    position: relative;
    top: 50px;
}

.bannerclasico {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 480px;
    height: 60px;
    bottom: 0px;
    background-color: red;
    display: none;
}

.noDataContainer {
    width: 95%;
    margin: 0 2.5%;
    height: 300px;
    text-align: center;
    h3 {
        text-align: center;
    }
    p {
        text-align: center;
    }
    .iconGrande {
        font-size: 6rem;
    }
}

.bgWhite {
    height: initial !important;
    background-color: #ffffff !important;
    background-image: none;
}

.margin_buttom_banner {
    margin-bottom: 80px;
}

.color_error {
    color: var(--ion-color-danger);
    font-weight: bolder;
}

.margin_icon {
    margin: 5px 7px;
}

@media only screen and (max-width: 320px) {}

.pagination-button-wrapper {
    display: inline-block;
    width: 100%;
    height: 120px;
    text-align: center;
    vertical-align: top;
}

.pagination-page-info {
    text-align: center;
    font-size: 14px;
    color: var(--ion-color-primary);
    margin: 20px;
}

.texto_titulo_efecto {
    background-image: url(assets/fonts/fuent_efecto1.gif);
    background-size: cover;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text;
    text-transform: uppercase;
    font-weight: bolder;
    margin: 10px 0;
}

.texto_store_numero {
    font-size: 2em;
    color: steelblue;
    font-weight: bolder;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 5px 10px;
}

.swiper-slide img {
    object-fit: cover;
    text-align: center;
}

.milista {
    li {
        text-align: left;
        font-weight: 600;
        color: var(--ion-color-primary);
    }
}

.fontSize2 {
    font-size: 2.5em;
}

.fontType2 {
    font-size: 2.5em;
    text-align: center;
    color: white;
    font-weight: bold;
}

.icon_home_bt {
    height: 90px;
    width: 90px;
}

.bt_play_margin {
    margin-top: 25px;
}

.read-more-button {
    color: var(--ion-color-primary);
    font-weight: 700;
}

.skeleton {
    border-color: #1e2226;
    background-color: #888;
    min-height: 90px;
}

.titulo_boton_home {
    h3 {
        background-color: #f1f1f1;
        font-size: 14px;
        color: var(--ion-color-primary);
        border-radius: 8px;
        border: 1px solid;
        margin: -8px 10px 10px 0px;
        padding: 0px;
    }
}

:root {
    //ion-content {--overflow:hidden} //hidden
}

.contenido_scroll {
    overflow-y: auto !important;
    height: 100vh;
    padding-bottom: 300px;
}

.imgLogoActivo {
    max-width: 70%;
    border: 0;
}

.imgLogoInactivo {
    max-width: 70%;
    border: 0;
    opacity: 0.2;
}

.separador {
    border: 2px #888;
}

.titulo_premio_logro {
    font-size: 1.5em;
    font-family: "Cabin Regular";
    color: var(--ion-color-success);
    text-align: center;
}

.color_premio {
    font-weight: bolder;
    font-size: 1.6em;
    color: green;
}

.minimo_card_logro {
    min-height: 600px;
}

.opacidad {
    opacity: 0.2;
}

.scroll_gptbox {
    height: 150px;
    overflow-y: scroll;
    display: block;
    z-index: 1000;
}

.w100 {
    width: 100%;
}

.bannerlocalContainer {
    z-index: 1;
    position: absolute;
    bottom: 0px;
    height: 62px;
    background-color: #f0f0f0;
}

@media only screen and (max-width: 413px) {
    .bannerlocal {
        cursor: pointer;
        margin-bottom: var(--ion-safe-area-bottom);
        z-index: 1;
        position: absolute;
        bottom: 0px;
        height: 60px;
        background-color: #f0f0f0;
        min-width: 100%;
        text-align: center;
        h1 {
            font-size: 1.3em;
            padding: 0px;
            margin: 2px;
            font-weight: bolder;
        }
        img {
            margin: 0px;
            padding: 0px;
        }
    }
}

.centrarBanner {
    display: grid;
    place-content: center;
    height: 60px;
}

@media only screen and (min-width: 1024px) {
    .bannerlocal240,
    .bannerlocalLateral {
        cursor: pointer;
        z-index: 1;
        height: auto;
        min-height: 100%;
        background-color: #f0f0f0;
        min-width: 96%;
        text-align: center;
        display: grid;
        place-content: center;
        h1 {
            font-size: 1.5em;
            padding: 0px;
            margin: 2px;
            text-align: center;
            font-weight: bolder;
        }
        img {
            margin: 0px;
            padding: 0px;
        }
    }
    .bannerlocal240 {
        min-height: 200px;
    }
}
.bannerlocalIntersticial{
    cursor: pointer;
    z-index: 1;
    height: auto;
    min-height: 100%;
    background-color: #f0f0f0;
    min-width: 96%;
    text-align: center;
    display: grid;
    font-size: 1.2em;
    place-content: center;
    h1 {
        font-size: 1.3em;
        padding: 0px;
        margin: 2px;
        text-align: center;
        font-weight: bolder;
    }
    img {
        margin: 0px;
        padding: 0px;
    }
}