.recuperar_contrasenia {
  text-decoration: underline;
  color: var(--ion-color-secondary);
  display: block;
  width: 100%;
  text-align: center;
  padding-right: 20px;
  font-size: 0.7em;
  height: 30px;
  margin-top: 20px;
  cursor: pointer;
}

.salto_linea {
  display: block;
  width: 100%;
  height: 15px;
}

.login_buttons_group {
  width: 100vw;
  height: 136px; //136px
  position: fixed;
  top: calc(100vh - 290px); //calc(100vh - 290px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
}

.login_buttons_group_home {
  width: 100vw;
  height: 250px; //136px
  position: fixed;
  bottom: 90px;
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  margin: 0 auto;
}

.login_logo {
  width: auto;
  max-height: 180px;
  height: 100%;
}

.mini {
  text-align: center;
}

.dark-theme {
  body {
    background-color: black;
  }
  .bg_app_content {
    box-shadow: inset 0 0 0 2000px rgba(9, 9, 9, 0.8);
  }
  background-color: black;
  .divNombreApp {
    padding: 20px 0px;
    text-align: center;
    width: 100%;
    display: block;
    color: white;
    opacity: 0.8;
    background: black;
    h1 {
      font-size: 3em;
      font-family: "Cabin Regular";
      color: white;
    }
    h2 {
      font-size: 1.5em;
      font-family: "Cabin Regular";
    }
  }
}

.bg_app_content_login {
  width: 100vw;
  height: 100vh;
  background-image: url("/assets/images/fondo_pc.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  --background: transparent;
  background-position: center;
}

@media only screen and (min-width: 768px) {
  .login_buttons_group_home {
    display: none;
  }
}
