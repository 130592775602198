.icon_grande_tiempo{
    width: 120px;
}
.mini_icon{
 width: 30px;
}
.TemperaturaActual{
    font-size: 4em;
    color: green;
}
.temperatura_hora{
    font-size: 1em;
    color: green;
}
.horas{
    font-weight: bold;
}
.date_day{
    display: block;
    font-weight: bold;
}
.enlaceCompany{
 float: right;
 margin: 5px;
 color: blue;
}